<template>
    <div class="slices">
        <template v-for="(slice, index) in slices">
            <component
                :is="$formatSliceType(slice.slice_type)"
                :key="index"
                :slice="slice"
                :document="document"
            />
        </template>
    </div>
</template>

<script>
import Vue from 'vue';

const ComponentContext = require.context('@/components/slices', true, /\.vue$/i, 'eager');
ComponentContext.keys().forEach((componentFilePath) => {
    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));
});

export default {
    props: {
        slices: {
            type: Array,
            required: true,
            default: () => {}
        },
        document: {
            type: Object,
            required: false,
            default: null
        }
    },
};
</script>
