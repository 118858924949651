<!-- Only a wrapper for the CardAuction component,
     if we in the future need to do anything in the slice -->

<template>
    <AuctionForm />
</template>

<script>
import AuctionForm from '@/components/application/Auction.vue';
export default {
    components: {
        AuctionForm
    },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    }
};
</script>