<template>
    <Base
        :inputs="getInputs()"
    >
        <template #header>
            <h1>Ansök och jämför flera kreditkort på 1 UC</h1>
            <p>Färdigställ din ansökan och få svar direkt.</p>
            <expand 
                class="form-container-expander"    
                :button="$translate('card_application_info', 'Fördelar och information')"
            >
                <AuctionInformation />
            </expand>
        </template>
        <template #sidebar>
            <div 
                v-if="$store.state.settings.auction_image && $store.state.settings.auction_image.url" 
                class="sidebar-image"
            >
                <prismic-image
                    :img="$store.state.settings.auction_image"
                    :lazy="false"
                    w="200"
                />
            </div>
            <AuctionInformation />
        </template>

        <template #terms>
            <p class="terms">
                Genom att genomföra ansökan genom Kortio.se accepterar 
                du våra <nuxt-link to="/anvandarvillkor/">villkor</nuxt-link>.
            </p>
        </template>
    </Base>
</template>

<script>
import Base from '@/components/application/Base.vue';
import Expand from '@/components/slices/list/list-item/list-item-components/Expand.vue';
import Inputs from '@/mixins/inputs.js';
import AuctionInformation from '@/components/application/sidebar/AuctionInformation.vue';

export default {
    mixins: [
        Inputs
    ],
    components: {
        Base, 
        Expand,
        AuctionInformation
    },
    methods: {

        getInputs() {
            const inputs = {};

            inputs.credit = this.getCreditAmountInput(
                10000,
                200000
            );

            inputs.personalNumber = this.getPersonalNumberInput();
            inputs.phoneNumber = this.getPhoneNumberInput();
            inputs.email = this.getEmailInput();

            inputs.youQuestions = this.getSubHeader('Frågor om dig');
            inputs.employmentStatus = this.getEmploymentStatusInput();
            inputs.employmentTo = this.getEmploymentToInput();
            inputs.civilStatus = this.getCivilStatus();
            inputs.householdNumberChildren = this.getHouseholdNumberChildren();
            inputs.householdNumberAdults = this.getHouseholdNumberAdults();
            inputs.carOwner = this.getCarOwnerInput();
            
            inputs.economyQuestions = this.getSubHeader('Frågor om din ekonomi');
            inputs.monthlyIncome = this.getMonthlyIncomeInput();
            inputs.incomeSource = this.getIncomeSource();
            inputs.monthlyCreditCost = this.getMonthlyCreditCost();
            inputs.monthlyLivingCost = this.getMonthlyLivingCost();
            inputs.monthlyMortgageCost = this.getMonthlyMortgageCost();
            inputs.housing = this.getHousingInput();
            inputs.housingCost = this.getHousingCost();
            inputs.totalCreditAmount = this.getTotalCreditAmount();

            inputs.lawQuestions = this.getSubHeader('Frågor enligt penningstvättslagen');
            inputs.isPep = this.getPepInput();
            inputs.pepTitle = this.getPepTitleInput();
            inputs.pepConnectionRelation = this.getPepConnectionRelationInput();
            inputs.pepConnectionPersonalNumber = this.getPepConnectionPersonalNumberInput();
            inputs.pepConnectionName = this.getPepConnectionNameInput();
            inputs.pepConnectionCountry = this.getPepConnectionCountryInput();

            return inputs;
        },
    }
}
</script>