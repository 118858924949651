<template>
    <Information 
        :usp-box-title="settings.auction_usp_box_title"
        :usp-box-description="settings.auction_usp_box_description"
        :pros="settings.auction_pros"
        :compliance-example="settings.auction_compliance_example"
    />
</template>

<script>
import Information from '@/components/application/sidebar/Information.vue';
export default {
    components: {
        Information
    },
    computed: {
        settings() {
            return this.$store.state.settings;
        }
    }
}
</script>